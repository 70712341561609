'use strict';

const $ = require('./../../../node_modules/jquery/dist/jquery');

$(document).ready(function () {
  let $personalBtn = $('.personal__btn');
  let $personalChange = $('#data-change');
  let $personalData = $('.personal__data');
  let $personalSubmit = $('.personal__submit');
  let hiddenClass = 'hidden';

  const toggleFormState = function () {
    $personalData.find('input:not([type=hidden])').attr('disabled', function (i, val) {
      return !val;
    });
    $personalSubmit.toggleClass(hiddenClass);
    $personalBtn.toggleClass(hiddenClass);
  };

  $personalChange.click(function (evt) {
    evt.preventDefault();
    toggleFormState();
  });

  $personalData.submit(function (evt) {
    evt.preventDefault();

    // $.when(toggleFormState()).then(function () {
    //   evt.target.submit();
    // });
  });
});
