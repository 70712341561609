'use strict';

const MENU_TIME = 600;
const TAB_TIME = 400;
const PRIME_COLOR = '#5b1704';
const Width = {
  LAPTOP: 1024
};

const $ = require('./../../../node_modules/jquery/dist/jquery');

let $mainNav = $('.main-nav');

$mainNav.tabs({
  collapsible: true,
  active: false,
  show: {
    duration: TAB_TIME
  },
  hide: false,
  classes: {
    'ui-tabs-active': 'active'
  }
});

const initCustomScroll = function () {
  if (window.screen.width >= Width.LAPTOP) {
    $('.main-nav__content').niceScroll({
      cursorcolor: PRIME_COLOR,
      cursorborder: 'none',
      cursorwidth: 30,
      cursorfixedheight: 30,
      cursorborderradius: '50%',
      autohidemode: false,
      horizrailenabled: false,
      background: PRIME_COLOR,
      railoffset: true
    });
  }
};

const toggleMenu = function (props) {
  const isClosed = ($(props.menu).css('display') === 'none');
  let $openIcon = $(props.toggle).find('.toggle__icon--open');
  let $closeIcon = $(props.toggle).find('.toggle__icon--close');

  $(props.menu).fadeToggle(props.duration);
  window.setTimeout(props.cb, 0);

  if (isClosed) {
    $openIcon.fadeOut(props.duration / 2);

    $openIcon.promise().done(function () {
      $closeIcon.fadeIn(props.duration / 2);

      const links = [];
      let $tabs = $($mainNav).find('.ui-tabs-anchor');

      $tabs.each(function (i, it) {
        if (it.getAttribute('href')[0] !== '#') {
          links.push(i);

          $(it).click(function () {
            window.location.href = it.href;
          });
        } else {
          $(it).addClass('tab');
        }
      });

      $mainNav.tabs('option', 'disabled', links);
    });
  } else {
    $closeIcon.fadeOut(props.duration / 2);

    $closeIcon.promise().done(function () {
      $openIcon.fadeIn(props.duration / 2);
    });
  }
};

$('.page-header__toggle').click(function (evt) {
  evt.preventDefault();

  toggleMenu({
    menu: $mainNav,
    toggle: evt.currentTarget,
    duration: MENU_TIME,
    cb: initCustomScroll
  });
});
