'use strict';

window.jQuery = require('./../../node_modules/jquery/dist/jquery');
const $ = require('./../../node_modules/jquery/dist/jquery');
const Swiper = require('./../../node_modules/swiper/dist/js/swiper');
const modal = require('./../../node_modules/micromodal/dist/micromodal');

require('./lib/jquery-ui');
require('./../../node_modules/jquery.nicescroll/dist/jquery.nicescroll');
require('./../../node_modules/jquery.dotdotdot/dist/jquery.dotdotdot');
require('./app/menu');
require('./app/slider');
// require('./app/map');
require('./app/user-area');
require('./app/fancy-gallery');
// require('./app/custom');

const PRIME_COLOR = '#5b1704';
const TAB_TIME = 400;
const Width = {
  MOBILE: 320,
  Tablet: {
    S: 480,
    M: 768
  },
  LAPTOP: 1024
};

$(document).ready(function () {
  modal.init({
    awaitCloseAnimation: true,
    onShow: function (modal) {
      $(modal).find('.modal__close path').attr('data-micromodal-close', true);
    }
  });

  const dropDown = function (props) {
    $(props.toggle).click(function (evt) {
      evt.preventDefault();

      $(evt.currentTarget).toggleClass('active');
      $(evt.currentTarget).next(props.content).slideToggle();
    });
  };

  if (window.screen.width < Width.LAPTOP) {
    dropDown({
      toggle: '.archives__title',
      content: '.archives__content'
    });
  }

  dropDown({
    toggle: '.service--dropable .service__title',
    content: '.service--dropable .service__content'
  });

  if (window.screen.width >= Width.LAPTOP) {
    $('.archives__content').niceScroll({
      cursorcolor: PRIME_COLOR,
      cursorborder: 'none',
      cursorwidth: 30,
      cursorfixedheight: 30,
      cursorborderradius: '50%',
      autohidemode: false,
      horizrailenabled: false,
      background: PRIME_COLOR,
      railoffset: true,
      railalign: 'left'
    });
  }

  let addressesHorizontal = false;

  if (window.screen.width < Width.LAPTOP) {
    addressesHorizontal = true;
  }

  $('.map__addresses').niceScroll({
    cursorcolor: PRIME_COLOR,
    cursorborder: 'none',
    cursorwidth: 30,
    cursorfixedheight: 30,
    cursorborderradius: '50%',
    autohidemode: false,
    horizrailenabled: addressesHorizontal,
    background: PRIME_COLOR,
    railoffset: true,
    railalign: 'left',
    railvalign: 'top',
    emulatetouch: true,
    cursordragontouch: true
  });

  if (window.screen.width >= Width.LAPTOP) {
    $('#map_addresses').accordion({
      header: '.map__city',
      heightStyle: 'content',
      classes: {
        'ui-accordion-header-active': 'map__city--active'
      }
    });
  } else {
    let addressSwiper;
    let slides = 2.5;

    if (window.screen.width <= Width.Tablet.M) {
      slides = 2;
    }

    if ((window.screen.width <= Width.Tablet.S)) {
      slides = 1.5;
    }

    if ((window.screen.width <= 380)) {
      slides = 1.1;
    }

    const wrapperClass = 'swiper-wrapper';

    const switchWrapper = function () {
      $('.map__list').each(function (i, it) {
        if ($(it).hasClass(wrapperClass)) {
          $(it).removeClass(wrapperClass);
        }
      });

      $('.map__list[aria-hidden="false"]').addClass(wrapperClass);
    };

    const updateSlider = function () {
      switchWrapper();

      addressSwiper = new Swiper('.map__content--mobile', {
        slidesPerView: slides
      });
    };

    $('.map__wrapper').tabs({
      show: {
        duration: TAB_TIME
      },
      hide: {
        duration: TAB_TIME
      },
      classes: {
        'ui-tabs-active': 'map__city--active'
      },
      create: updateSlider,
      activate: updateSlider
    });
  }

  let $documents = $('.page-about__documents');

  if ($documents[0]) {
    const maxHeight = $documents.next('.column').height();

    $documents.css({
      'max-height': maxHeight
    });

    $('.documents').niceScroll({
      cursorcolor: PRIME_COLOR,
      cursorborder: 'none',
      cursorwidth: 30,
      cursorfixedheight: 30,
      cursorborderradius: '50%',
      autohidemode: false,
      background: PRIME_COLOR,
      railoffset: true,
      railalign: 'left',
      railvalign: 'top',
      emulatetouch: true,
      cursordragontouch: true
    });
  }

  $('.articles__text').dotdotdot({
    height: 60
  });
});
