'use strict';

const $ = require('./../../../node_modules/jquery/dist/jquery');

require('./../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox');
require('./../../../node_modules/jquery-zoom/jquery.zoom');

$(document).ready(function () {
  const settings = {
    buttons: [
      "share",
      "slideShow",
      "fullScreen",
      "download",
      "thumbs",
      "close"
    ],
    thumbs: {
      autoStart: true
    },
    afterShow: function (instance, slide) {
      slide.$content.zoom();
    }
  };

  $('.page-library__table .table__link').each(function (i) {
    let selector = '[data-fancybox="library-' + i + '"]';
    $(selector).fancybox(settings);
  });

  $('.page-pointer__photo').each(function (i) {
    let selector = '[data-fancybox="collection-' + i + '"]';

    settings.baseTpl = '<div class="fancybox-container" role="dialog" tabindex="-1">' +
      '<div class="fancybox-bg"></div>' +
      '<div class="fancybox-inner">' +
        '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
        '<div class="fancybox-toolbar">{{buttons}}</div>' +
        '<div class="fancybox-navigation">{{arrows}}</div>' +
        '<div class="quick-view-content">' +
          '<div class="quick-view-carousel">' +
            '<div class="fancybox-stage"></div>' +
          '</div>' +
          '<div class="quick-view-aside"></div>' +
        '</div>' +
      '</div>' +
    '</div>';

    settings.afterShow = function (instance, slide) {
      slide.$content.zoom();
      
      const $aside = $('.quick-view-aside');

      if (!slide.$content.hasClass('fancybox-error')) {
        const contentTop = slide.$content[0].getBoundingClientRect().top;

        $aside.css({
          top: contentTop,
          bottom: contentTop,
          left: slide.$content[0].getBoundingClientRect().left + slide.$content.outerWidth()
        });
      }

      $aside.html(slide.opts.caption);
      $aside.fadeIn();
    };

    $(selector).fancybox(settings);
  });

  $('.detail__more').fancybox(settings);
  $('.banners__item a[data-fancybox]').fancybox(settings);
  $('.table__link--doc[data-fancybox]').fancybox(settings);
});
