'use strict';

const Swiper = require('./../../../node_modules/swiper/dist/js/swiper');

const Width = {
  Tablet: {
    S: 480
  },
  LAPTOP: 1024
};

const createLink = function (props) {
  let linkEl = document.createElement('a');

  linkEl.classList.add(props.class);
  linkEl.addEventListener('click', function () {
    window.location = props.href;
  });
  linkEl.textContent = props.text;

  return linkEl;
};

new Swiper('.promo__wrapper', {
  spaceBetween: 5,
  pagination: {
    el: '.promo__pagination',
    bulletElement: 'button',
    bulletClass: 'slider-pagination__bullet',
    bulletActiveClass: 'slider-pagination__bullet--active',
    clickable: true
  }
});

if (document.querySelector('.news__wrapper')) {
  const newsSwiper = new Swiper('.news__wrapper', {
    pagination: {
      el: '.news__pagination',
      bulletClass: 'slider-pagination__bullet',
      bulletActiveClass: 'slider-pagination__bullet--active',
      clickable: true
    },
    on: {
      transitionStart: function () {
        this.pagination.bullets.forEach = [].forEach;
        this.pagination.bullets.forEach(function (it) {
          if (it.firstElementChild) {
            it.removeChild(it.firstElementChild);
          }
        });
      },
      transitionEnd: function () {
        const instance = this;

        this.pagination.bullets[this.activeIndex].appendChild(createLink({
          location: '#',
          class: 'slider-pagination__link',
          text: 'Подробнее',
          href: instance.slides[instance.activeIndex].dataset.href
        }));
      }
    }
  });

  newsSwiper.pagination.bullets[0].appendChild(createLink({
    location: '#',
    class: 'slider-pagination__link',
    text: 'Подробнее',
    href: newsSwiper.slides[0].dataset.href
  }));
}

new Swiper('.banners__wrapper', {
  slidesPerView: 'auto',
  navigation: {
    prevEl: '.banners__control--prev',
    nextEl: '.banners__control--next',
    disabledClass: 'banners__control--disabled'
  }
});

new Swiper('.news-section', {
  slidesPerView: 4,
  spaceBetween: 30,
  navigation: {
    prevEl: '.news-section__control--prev',
    nextEl: '.news-section__control--next',
    disabledClass: 'news-section__control--disabled'
  },
  breakpoints: {
    768: {
      slidesPerView: 2.8
    },
    480: {
      slidesPerView: 1.8
    },
    360: {
      slidesPerView: 1.3
    }
  }
});

const filterSwiper = new Swiper('#filter-swiper', {
  spaceBetween: 15,
  slidesPerView: 4.5,
  breakpoints: {
    479: {
      slidesPerView: 2.3
    },
    767: {
      slidesPerView: 3.5
    }
  }
});

if (window.screen.width >= Width.LAPTOP && document.querySelector('.page-search__filter')) {
  filterSwiper.destroy();
}

new Swiper('.leadership__content', {
  spaceBetween: 50,
  slidesPerView: 5,
  scrollbar: {
    el: '.leadership__scroll',
    hide: false,
    draggable: true,
    dragClass: 'leadership__drag',
    dragSize: 30
  },
  breakpoints: {
    479: {
      slidesPerView: 1.7
    },
    767: {
      slidesPerView: 2.3
    },
    991: {
      slidesPerView: 3.5
    }
  }
});

new Swiper('.documents__content', {
  spaceBetween: 40,
  slidesPerView: 5,
  scrollbar: {
    el: '.documents__scroll',
    hide: false,
    draggable: true,
    dragClass: 'documents__drag',
    dragSize: 30
  },
  breakpoints: {
    479: {
      slidesPerView: 2.4
    },
    767: {
      slidesPerView: 3.4
    }
  }
});

// if (window.screen.width >= Width.LAPTOP && document.querySelector('.documents__content')) {
//   docSwiper.destroy();
// }
